<template>
  <div class="custom-captcha">
    <div>
      <span>{{ num1 }}</span>
      
      <button class="btnOperator"
        type="button"
        v-for="(option, index1) in operators"
        :key="index1"
        :class="{ 'selected': operator === option }"
        @click="selectOperator(option)"
      >
        {{ option }}
      </button>
     
      <span>{{ num2 }}</span> =
   
      
      <button class="btnOperator"
      type="button"
      v-for="option in reversedAnswerOptions"
      :key="option.id"
      :class="{ 'selected': userAnswer === option.answer }"
      @click="selectAnswer(option.answer)"
      >
        {{ option.answer }}
      </button>
      <span  v-if="isCorrect" class="success-message checkmark">&#10003;</span> 
    </div>
    <div class="error-message">{{ errorMessage }}</div>

      

    
  </div>
</template>




<script>
export default {
  name: 'CustomCaptcha',

  data() {
  return {
    num1: Math.floor(Math.random() * 10)+1,
    num2: Math.floor(Math.random() * 10)+1,
    operators: ['+', '-', 'x'],
    operator: '',
    answerOptions: [],
    userAnswer: '',
    errorMessage: '',
    isCorrect: false,
    selectedOperator: null,
      selectedResult: null,
  };
},
created() {
  this.generateQuestion();
},
computed: {
  reversedAnswerOptions() {
    return this.answerOptions.slice().reverse();
  },
},

methods: {
    generateQuestion() {
      this.answerOptions = this.generateAnswerOptions();
    },
    calculateCorrectAnswer(operator) {
      switch (operator) {
        case '+':
          return this.num1 + this.num2;
        case '-':
          return this.num1 - this.num2;
        case 'x':
          return this.num1 * this.num2;
      }
    },
    generateAnswerOptions() {
      const options = [];
      for (const operator of this.operators) {
        const id = `answer-${this.num1}-${this.num2}-${operator}`;
        const answer = this.calculateCorrectAnswer(operator);
        options.push({ id, answer });
      }
      return options;
    },
    checkAnswer() {
      const correctAnswer = this.calculateCorrectAnswer(this.operator);
      if (parseInt(this.userAnswer) === correctAnswer) {
        this.errorMessage = '';
        this.isCorrect = true;
        this.$emit('validation', true);
        // Validierung erfolgreich, fahren Sie mit der Formulareinreichung fort
      } else {
        this.errorMessage = 'Falsche Antwort. Bitte versuchen Sie es erneut.';
        this.resetFields();
        this.$emit('validation', false);
      }
    },
    resetFields() {
      this.operator = '';
      this.userAnswer = '';
      this.num1 = Math.floor(Math.random() * 10)+1;
      this.num2 = Math.floor(Math.random() * 10)+1;
      this.isCorrect = false;
      this.generateQuestion(); // Generiert eine neue Frage, nachdem eine falsche Antwort gegeben wurde
    },
    selectAnswer(option) {
      this.userAnswer = option;
    },
    selectOperator(option) {
      this.operator = option;
    },

  },

  watch: {
    operator() {
      if (this.operator && this.userAnswer) {
        this.checkAnswer();
      }
    },
    userAnswer() {
      if (this.operator && this.userAnswer) {
        this.checkAnswer();
      }
    },
  },


};
</script>

<style scoped>
/* ... (vorherige Stile) */
/* .success-message {
  color: green;

  margin-left: 5px;
  align-items: center;
  gap: 4px;
}
.checkmark {
  font-weight: bold;
}

.btnOperator {
  background-color: #c6c6c6;
  border: 0px solid #999;
  padding: 6px 12px;
  margin: 5px;
  width: 30px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
justify-content: center;
}

.btnOperator:hover {
  background-color: #a0a0a0;
}

.btnOperator.selected {
  background-color: #f59c00;
  color: white;
  
}

span {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;;
} */
</style>


