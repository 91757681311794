<template>

  <div id="app">
   
    <CustomCaptcha @validation="handleCaptchaValidation" msg=""/>
    <p v-if="errorMessage" >{{ errorMessage }}</p>
   
    <button type="button" @click="submitForm" id="submitbutton" class="submit" >Absenden</button>
  </div>
</template>

<script>
import CustomCaptcha from './components/CustomCaptcha.vue'

export default {
  name: 'App',
  components: {
    CustomCaptcha
  },
  
  data() {
    return {
      isCaptchaValid: false,
      errorMessage: '',
      token: '',
      tokenElement: '',
      myTokenField: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.myTokenField = document.getElementById('myTokenField')
      if (this.myTokenField) {
        this.token = this.myTokenField.getAttribute('token')
      }
    })
  },
  methods: {
    changeToken() {

      console.log(this.token);
      this.myTokenField.setAttribute('value',this.token);
      

      //this.tokenElement = document.getElementsByName("REQUEST_TOKEN");
     //     this.tokenElement.setAttribute('value', this.tokenElement.getAttribute('token'));


    },

    handleCaptchaValidation(isValid) {
      this.isCaptchaValid = isValid;
      const captchaValidationInput = document.getElementById('captcha-validation');
      captchaValidationInput.value = isValid ? '1604' : '0';
      this.errorMessage = "";
      if (isValid) {
        // Wenn die Antwort korrekt ist, versenden Sie das Formular
        //document.getElementById('contao-form').submit();
        console.log('Valid');
      } else {
        // Zeigen Sie eine Fehlermeldung an oder lassen Sie das Formular unverändert
        console.log('Invalid');
       


      }
    },
  
    submitForm() {
      if (this.isCaptchaValid) {
          // Wenn die Antwort korrekt ist, versenden Sie das Formular
          
          //console.log('Formsubmit');
          this.errorMessage = "";

          this.changeToken();

          document.getElementById('contao-form').submit();
        } else {
          // Zeigen Sie eine Fehlermeldung an oder lassen Sie das Formular unverändert
          //console.log('not submitted');
          this.errorMessage = "Die Aufgabe wurde nicht korrekt gelöst.";
        }

    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

/* .submit {
  margin-top: 20px;
} */
</style>
